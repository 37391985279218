import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "@/router"

Vue.use(AclInstaller);

let initialRole = "public";

let userInfo = JSON.parse(localStorage.getItem("userInfo"));
let appData = JSON.parse(localStorage.getItem("appData"));

if (userInfo && userInfo.profile.profile_type && appData && appData.profileTypes) {
  initialRole = (appData.profileTypes.find(p => p.profile_type == userInfo.profile.profile_type).role) || "public";
}

export default new AclCreate({
  initial: initialRole,
  // notfound: "/pages/error-404",
  router,
  acceptLocalRules: true,
  globalRules: {
    superadmin: new AclRule("superadmin").generate(),
    admin: new AclRule("admin").generate(),
    owner: new AclRule("owner").generate(),
    school: new AclRule("school").generate(),
    store: new AclRule("store").generate(),
    manager: new AclRule("manager").or("owner").generate(),
    pos: new AclRule("pos").or("admin").or("owner").or("manager"),
    public: new AclRule("admin").or("owner").or("manager").or("superadmin").generate(),
  }
})
