// import auth from "@/auth/authService";

export default {
    // isUserLoggedIn: () => {
    //     let isAuthenticated = false
        
    //     if (auth.isAuthenticated()) isAuthenticated = true
    //     else isAuthenticated = false
        
    //     return (localStorage.getItem('userInfo') && isAuthenticated)
    // }
    isUserLoggedIn: () => {
        let isAuthenticated = false
        
        let exp = localStorage.getItem('expire');

        if (new Date(Date.now()) < new Date(exp * 1000)) isAuthenticated = true;

        return isAuthenticated;
    }
}