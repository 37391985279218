// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {

	// COMPONENT
		// vx-autosuggest
	// starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return "xl"
    else if (state.windowWidth >= 992) return "lg"
    else if (state.windowWidth >= 768) return "md"
    else if (state.windowWidth >= 576) return "sm"
    else return "xs"
  },
  // isLoggedIn: () => { return store.state.auth.isUserLoggedIn() }
  isLoggedIn: () => { 
    return localStorage.getItem('userInfo.auth.token') || false 
  },
  getExternalUrl: state => key => {
    const url = state.AppData.externalUrls.find(u => u.key == key)
    if (!url) return false
    return url.protocol + '://' + url.subdomain + url.domain;
  }
}

export default getters
