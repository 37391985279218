import axios from "../../http/axios/index.js"

export default {
  SET_BEARER(state, accessToken) {
    localStorage.setItem("Authorization", 'Bearer ' + accessToken)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
  },
  SET_EXPIRE(state, date) {
    localStorage.setItem("expire", date)
  },
  SET_FINGERPRINT(state, fingerprint) {
    localStorage.setItem("x-secure-identity", fingerprint);
    axios.defaults.headers.common['x-secure-identity'] = fingerprint;
  }
}