import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)


// axios
import axios from "./axios.js"
Vue.prototype.$http = axios

// API Calls
import "./http/requests"

// mock
// import "./fake-db/index.js"

// ACL
import acl from './acl/acl'

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);

// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'

// I18n
import VueI18n from 'vue-i18n';
import esMessages from 'vee-validate/dist/locale/es';
Vue.use(VueI18n);
const i18n = new VueI18n();
i18n.locale = "es";

// VeeValidate
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
  // i18nRootKey: 'validations',
  i18n,
  dictionary: {
    es: {
      messages: esMessages.messages,
      attributes: { name : "nombre", last_name : "apellido" }
    } 
  }
});

// Print html to paper
import VueHtmlToPaper from 'vue-html-to-paper';
Vue.use(VueHtmlToPaper, {name: '_blank',specs: ['fullscreen=yes','titlebar=yes','scrollbars=yes'],styles:[]});

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')
Vue.prototype.$baseUrlImg = process.env.VUE_APP_IMAGES || ''
Vue.prototype.$localeDateOptions = {year: "numeric", month: "2-digit", day: "2-digit", timeZone: "UTC"}

Vue.config.productionTip = false

Vue.filter('currencySign', function (currency, sign){
  if(currency != null) {
    var sansDec = currency.toFixed(2);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return sign + `${formatted}`;
  }
})
Vue.filter('currencyPercentage', function (currency, sign){
  if(currency != null) {
    var sansDec = currency.toFixed(3);
    return `${sansDec}` + sign;
  }
})

//Datepicker
import Datepicker from 'vuejs-datepicker';
import {es} from 'vuejs-datepicker/dist/locale';

Datepicker.props.language.default = () => es;
Vue.component('datepicker', Datepicker);

new Vue({
    router,
    store,
    acl,
    render: h => h(App)
}).$mount('#app')


