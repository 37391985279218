import jwt from "../../http/requests/auth/jwt/index.js"


// import firebase from 'firebase/app'
// import 'firebase/auth'
import router from '@/router'
/* import store from "../store.js" */

export default {
    loginAttempt({ dispatch }, payload) {

        // New payload for login action
        const newPayload = {
            userDetails: payload.userDetails,
            notify: payload.notify,
            closeAnimation: payload.closeAnimation
        }

        // If remember_me is enabled change firebase Persistence
        if (!payload.checkbox_remember_me) {
            dispatch('login', newPayload)
        } else {
            // Try to login
            dispatch('login', newPayload)
        }
    },
    updateUsername({ commit }, payload) {
        payload.user.updateProfile({
            displayName: payload.displayName
        }).then(() => {

            // If username update is success
              // update in localstorage
            let newUserData = Object.assign({}, payload.user.providerData[0])
            newUserData.displayName = payload.displayName
            commit('UPDATE_USER_INFO', newUserData, {root: true})

            // If reload is required to get fresh data after update
              // Reload current page
            if(payload.isReloadRequired) {
                router.push(router.currentRoute.query.to || '/')
            }
        }).catch((err) => {
              payload.notify({
                time: 8800,
                title: 'Error',
                text: err.message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            })
        })
    },


    // JWT
    loginJWT({ commit }, payload) {

      return new Promise((resolve,reject) => {
        jwt.login(payload.userDetails.email, payload.userDetails.password)
          .then(response => {
            // If there's user data in response
            if (response.data.status) {
              // Sellers cannot access to admin
              if (response.data.data.profile && response.data.data.profile.profile_type == 5)
                return reject({message: "Sin permisos de acceso."})

              // Owners with no institutions assigned cannot access to admin
              if (response.data.data.profile && response.data.data.profile.profile_type == 3 && (!response.data.data.institutions || (response.data.data.institutions && response.data.data.institutions.length == 0)) && (response.data.data.schools && response.data.data.schools.length == 0) )
                return reject({message: "Sin instituciones asignadas."})

              if(response.data.data.account) {
                  // Set bearer token in axios
                  commit("SET_BEARER", response.data.data.auth.token)
                  commit("SET_EXPIRE", response.data.data.auth.expire)

                  //Status 4 = Not Verified => First Login
                  if (response.data.data.account.status == 4) {
                    commit("UPDATE_USER_INFO",{email: response.data.data.account.email, status: response.data.data.account.status}, {root: true})
                    return resolve(response.data.data)
                  }

                  // Set accessToken
                  //localStorage.setItem("accessToken", response.data.data.account.token)

                  // New internal profile type for School Admins (6)
                  if( response.data.data.schools ) {
                    response.data.data.profile.profile_type = 6;
                  }

                  // Update user details
                  commit('UPDATE_USER_INFO', response.data.data, {root: true})

                  // Set institution and institutions
                  if(response.data.data.institutions) {
                    let obj = {
                      institution: response.data.data.institutions[0],
                    }
                    commit('UPDATE_USER_INFO', obj, {root: true})
                  }
                  if(response.data.data.schools) {
                    let obj = {
                      institution: response.data.data.schools[0],
                    }
                    commit('UPDATE_USER_INFO', obj, {root: true})
                  }
                  if(response.data.data.stores) {
                    let obj = {
                      store: response.data.data.stores[0],
                    }
                    commit('UPDATE_USER_INFO', obj, {root: true})
                  }

                  //Pending: get appData from API CALL
                  let appData = { profileTypes : [
                    { profile_type : 1, profile_type_description : "Acudiente", role: "guardian" },
                    { profile_type : 2, profile_type_description : "Estudiante", role: "student" },
                    { profile_type : 3, profile_type_description : "Admin Cafetería", role: "owner" },
                    { profile_type : 4, profile_type_description : "Gerente", role: "manager" },
                    { profile_type : 5, profile_type_description : "Cajero", role: "seller" },
                    { profile_type : 6, profile_type_description : "Admin Escuela", role: "school" },
                    { profile_type : 7, profile_type_description : "Admin Tienda", role: "store" },
                    { profile_type : 10, profile_type_description : "Admin País", role: "admin" },
                    { profile_type : 11, profile_type_description : "Super Admin", role: "superadmin" },
                  ]}

                  commit('UPDATE_APP_DATA', appData, {root: true})

                  // Set user role
                  // dispatch('updateUserRole', {userRole: response.data.data.profile.profile_type},{ root: true })

                  // Navigate User to homepage
                  // router.push({name: 'home'}) //router.currentRoute.query.to ||

                  resolve(response.data.data)
                } else {
                  reject({message: response.data.msg})
                }
            } else {
                reject({message: response.data.msg})
            }
          })
          .catch(error => {
              reject(error)
          })
      })
    },
    registerUserJWT({ commit }, payload) {

      const { displayName, email, password, confirmPassword } = payload.userDetails

      return new Promise((resolve,reject) => {

        // Check confirm password
        if(password !== confirmPassword) {
          reject({message: "Password doesn't match. Please try again."})
        }

        jwt.registerUser(displayName, email, password)
          .then(response => {
            // Redirect User
            router.push(router.currentRoute.query.to || '/')

            // Update data in localStorage
            localStorage.setItem("accessToken", response.data.accessToken)
            commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchAccessToken() {
      return new Promise((resolve) => {
        jwt.refreshToken().then(response => { resolve(response) })
      })
    },
    setFingerprint({ commit }, identity) {
      commit('SET_FINGERPRINT', identity);
    },
    logout({commit}) {
      // JWT login
      if(localStorage.getItem("Authorization")) {
        localStorage.removeItem("Authorization")
      }
      // Change role on logout. Same value as initialRole of acj.js
      // this.$acl.change('admin')

      localStorage.removeItem('userInfo')
      localStorage.removeItem('expire')
      localStorage.removeItem('appData')

      commit('LOGOUT', ['AppActiveUser', 'dataList', 'dataListInstitutions', 'reportModule'], {root: true}); //, 'dataListCategories'

      router.push('/login').catch(() => {})
    },
}

