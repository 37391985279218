import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'
import axios from './axios'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
              {
                path: '/',
                name: 'home',
                component: () => import('./views/Dashboard.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "public"
                }
              },
              {
                path: '/page2',
                name: 'page-2',
                component: () => import('./views/Page2.vue')
              },
              {
                path: '/institutions',
                name: 'institutionsCoffeshop',
                component: () => import('./views/institutions/Coffeshop.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/requestsAdmin',
                name: 'requestsAdmin',
                component: () => import('./views/reports/payments/RequestsAdmin.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/payments/pending',
                name: 'payments-pending',
                component: () => import('./views/payments/PendingPayments.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/requests',
                name: 'requests',
                component: () => import('./views/reports/payments/Requests.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "owner"
                }
              },
              {
                path: '/school/reports/requests',
                name: 'school-requests',
                component: () => import('./views/reports/payments/Requests.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "school"
                }
              },
              {
                path: '/sa/reports/requests',
                name: 'requestsSuperAdmin',
                component: () => import('./views/reports/payments/Requests.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "superadmin"
                }
              },
              {
                path: '/reports/service/payments',
                name: 'service-payments',
                component: () => import('./views/reports/payments/Service.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "school"
                }
              },
              {
                path: '/requestsPay',
                name: 'requestsPay',
                component: () => import('./views/payments/Payments.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "owner"
                },
              },
              {
                path: '/requestsPay/services',
                name: 'requestsPayServices',
                component: () => import('./views/payments/Payments.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "school"
                },
              },
              {
                path: '/sa/requestsPay',
                name: 'requestsPaySuperAdmin',
                component: () => import('./views/payments/Payments.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "superadmin"
                },
              },
              {
                path: '/institutions/owner',
                name: 'institutionsOwner',
                component: () => import('./views/institutions/Owner.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "owner"
                },
              },
              {
                path: '/institution/schools',
                name: 'institutionSchools',
                component: () => import('./views/institutions/School.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                },
              },
              {
                path: '/products',
                name: 'products',
                component: () => import('./views/Products.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "manager"
                }
              },
              {
                path: '/categories',
                name: 'categories',
                component: () => import('./views/Categories.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "admin"
                }
              },
              {
                path: '/services',
                name: 'services',
                component: () => import('./views/services/Services.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "admin"
                }
              },
              {
                path: '/services/subscribe',
                name: 'services-subscribe',
                component: () => import('./views/services/Subscribe.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "school"
                }
              },
              {
                path: '/services/:serviceId',
                name: 'service-schools',
                component: () => import('@/views/services/ServiceSchools.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/' },
                        { title: 'Servicios', url: '/services' },
                        { title: 'Service Name', active: true }
                    ],
                    // pageTitle: 'Escuelas Asociadas al Servicio',
                    rule: 'admin'
                },
              },
              {
                path: '/users',
                name: 'users',
                component: () => import('./views/user/user-list/UserList.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "pos"
                }
              },
              {
                path: '/users/user-edit/:userId',
                name: 'app-user-edit',
                component: () => import('@/views/user/user-edit/UserEdit.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/' },
                        { title: 'Usuarios', url: '/users' },
                        { title: 'Editar', active: true },
                    ],
                    pageTitle: 'Editar Usuario',
                    rule: "public",
                    requiresAuth: true
                },
              },
              {
                path: '/allergies',
                name: 'allergies',
                component: () => import('./views/Ingredients.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/users/user-add',
                name: 'app-user-add',
                component: () => import('@/views/user/user-add/AddUser.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/' },
                        { title: 'Usuarios', url: '/users' },
                        { title: 'Agregar', active: true },
                    ],
                    pageTitle: 'Agregar Usuario',
                    rule: "public",
                    requiresAuth: true
                },
              },
              {
                path: '/reports/sales',
                name: 'report-sales',
                component: () => import('@/views/reports/sales/General.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "public"
                }
              },
              {
                path: '/reports/canceled-sales',
                name: 'report-canceled-sales',
                component: () => import('@/views/reports/sales/CanceledSales.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "manager"
                }
              },
              // {
              //   path: '/reports/close-register',
              //   name: 'report-close-register',
              //   component: () => import('@/views/reports/sales/CloseRegister.vue'),
              //   meta: {
              //     requiresAuth: true,
              //     rule: "manager"
              //   }
              // },
              {
                path: '/reports/products',
                name: 'report-products',
                component: () => import('@/views/reports/inventory/Products.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "manager"
                }
              },
              {
                path: '/reports/inventory-transactions',
                name: 'report-inventory-transactions',
                component: () => import('@/views/reports/inventory/Transactions.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "manager"
                }
              },
              {
                path: '/reports/users/tiptap',
                name: 'report-users-tiptap',
                component: () => import('@/views/reports/users/UserList.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "public"
                }
              },
              {
                path: '/reports/users/institution',
                name: 'report-users-pos',
                component: () => import('@/views/reports/users/Admins.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "pos"
                }
              },
              {
                path: '/reports/sales/product',
                name: 'reports-sales-product',
                component: () => import('@/views/reports/sales/Product.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "manager"
                }
              },
              {
                path: '/reports/incomes',
                name: 'reports-incomes',
                component: () => import('@/views/reports/incomes/Income.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "public"
                }
              },
              {
                path: '/reports/loyalty/delivered',
                name: 'reports-delivered-rewards',
                component: () => import('@/views/reports/loyalty/DeliveredRewards.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/till/closes',
                name: 'reports-till-closes',
                component: () => import('@/views/reports/till/Closes.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "manager"
                }
              },
              {
                path: '/reports/till/open',
                name: 'reports-till-open',
                component: () => import('@/views/reports/till/Open.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "manager"
                }
              },
              {
                path: '/config',
                name: 'config-general',
                component: () => import('@/views/config/General.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "superadmin"
                }
              },
              {
                path: '/rewards',
                name: 'rewards',
                component: () => import('@/views/loyalty/Rewards.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/Requestrewards',
                name: 'Requestrewards',
                component: () => import('@/views/loyalty/Requestrewards.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/recharge/manual',
                name: 'ManualRecharge',
                component: () => import('@/views/payments/ManualRecharge.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/recharges',
                name: 'reports-recharges',
                component: () => import('@/views/reports/payments/Recharges.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/recharge/notices',
                name: 'recharge-notices',
                component: () => import('@/views/payments/NoticesRecharge.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/institution/school/services/:idSchool',
                name: 'school-services',
                component: () => import('@/views/institutions/school-services/SchoolServices.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "public"
                }
              },
              {
                path: '/institution/school/add',
                name: 'school-add',
                component: () => import('@/views/institutions/school/school-add-edit/SchoolAddEdit.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/institution/school/edit/:idSchool',
                name: 'school-edit',
                component: () => import('@/views/institutions/school/school-add-edit/SchoolAddEdit.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/service/payments/all',
                name: 'reports-service-payments',
                component: () => import('./views/reports/sales/Services.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/gop/defaultings',
                name: 'reports-gop-defaultings',
                component: () => import('./views/reports/guarantee-of-payment/Defaultings.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/gop/penalties',
                name: 'reports-gop-penalties',
                component: () => import('./views/reports/guarantee-of-payment/RecoveredPenalties.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/gop/user-account-status',
                name: 'reports-gop-user-account-status',
                component: () => import('./views/reports/guarantee-of-payment/UserAccountStatus.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/gop/withholdings',
                name: 'reports-gop-withholdings',
                component: () => import('./views/reports/guarantee-of-payment/Withholdings.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/gop/request/advance',
                name: 'gop-advance-requests',
                component: () => import('./views/payments/guarantee-of-payment/AdvanceRequests.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/gop/request/recurring',
                name: 'gop-advance-recurring',
                component: () => import('./views/payments/guarantee-of-payment/PaymentRequests.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/gop/guardians/charges',
                name: 'reports-gop-charges-guardians',
                component: () => import('./views/reports/guarantee-of-payment/ChargesGuardians.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              // Marketplace
              {
                path: '/marketplace/categories',
                name: 'marketplace-categories',
                component: () => import('./views/marketplace/Categories.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/marketplace/stores',
                name: 'marketplace-stores',
                component: () => import('./views/marketplace/Stores.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/marketplace/store/:idStore',
                name: 'store-edit',
                component: () => import('@/views/marketplace/store/AddEdit.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/marketplace/store',
                name: 'store-add',
                component: () => import('@/views/marketplace/store/AddEdit.vue'),
                meta: {
                  requiresAuth: true,
                  rule: "admin"
                }
              },
              {
                path: '/marketplace/products',
                name: 'marketplace-products',
                component: () => import('./views/marketplace/my-store/Products.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "store"
                }
              },
              {
                path: '/marketplace/reports/sales',
                name: 'marketplace-sales',
                component: () => import('./views/reports/marketplace/Sales.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "store"
                }
              },
              {
                path: '/reports/marketplace/sales',
                name: 'reports-marketplace-sales',
                component: () => import('./views/reports/marketplace/SalesByStore.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "admin"
                }
              },
              {
                path: '/marketplace/payment/request',
                name: 'marketplace-payment-request',
                component: () => import('./views/payments/Payments.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "store"
                }
              },
              {
                path: '/marketplace/reports/requests',
                name: 'marketplace-reports-requests',
                component: () => import('./views/reports/marketplace/PaymentRequests.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "store"
                }
              },
              // Direct Payment
              {
                path: '/direct-payment/entities',
                name: 'direct-payment-entities',
                component: () => import('./views/direct-payment/Entities.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "admin"
                }
              },
              {
                path: '/reports/direct-payment/requests',
                name: 'direct-payment-requests',
                component: () => import('./views/payments/direct-payment/PaymentRequests.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "school"
                }
              },
              {
                path: '/direct-payment/cash-out-request',
                name: 'direct-payment-cash-out-request',
                component: () => import('./views/payments/direct-payment/CashOutRequest.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "school"
                }
              },
              {
                path: '/reports/direct-payments',
                name: 'direct-payments',
                component: () => import('./views/reports/payments/DirectPayments.vue'),
                meta: {
                  requiresAuth : true,
                  rule: "school"
                }
              }
              // ToDo: delete following view & route
              // {
              //   path: '/reports/service/details/:id_main_institution',
              //   name: 'reports-service-details',
              //   component: () => import('./views/reports/incomes/IncomeServiceDetails.vue'),
              //   meta: {
              //     requiresAuth: true,
              //     rule: "admin"
              //   }
              // },
            ],
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
              {
                path: '/login',
                name: 'login',
                component: () => import('@/views/pages/Login.vue'),
                meta: {
                  requiresAuth: false,
                  rule: "public"
                }
              },
              {
                path: '/change-password',
                name: 'change-password',
                component: () => import('@/views/pages/ChangePassword.vue'),
                meta: {
                  requiresAuth: false,
                  rule: "public"
                }
              },
              {
                path: '/password-recovery',
                name: 'password-recovery',
                component: () => import('@/views/pages/PasswordRecovery.vue'),
                meta: {
                  requiresAuth: false,
                  rule: "public"
                }
              },
              {
                path: '/pages/error-404',
                name: 'page-error-404',
                component: () => import('@/views/pages/Error404.vue'),
                meta: {
                  requiresAuth: false,
                  rule: "public"
                }
              },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

router.beforeEach((to, from, next) => {
  axios.interceptors.response.use((response) => {
    if (response && (response.status === 402 || response.status === 403 && store.state.auth.isUserLoggedIn())) {
      store.dispatch('auth/logout');
    }
    return response
  })

  // Update Breadcrumb dynamically
  if(to.name == 'service-schools') {
    const service = store.getters['servicesModule/getService'](to.params.serviceId)
    to.meta.breadcrumb[2].title = 'Escuelas asociadas al servicio "' + service.name_service + '"'
  }
  // End Update Breadcrumb dynamically

  store.dispatch('verifyToken');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.isUserLoggedIn()) {
      //next({name : 'login', replace: true})
      store.dispatch('auth/logout');
    } else {
        if (!store.state.AppActiveUser.account) {
          store.dispatch('verifyUserInfo')
        }
        if (!store.state.AppData) {
          store.dispatch('verifyAppData')
        }
        next()
    }
  } else {
    if (to.name == 'login' && store.state.auth.isUserLoggedIn()) {
      next({ name: 'home'})
    }
    else {
      next()
    }

  }

})

export default router
