// axios
import axios from 'axios'

const domain = process.env.VUE_APP_DOMAIN || "/api/";

export default axios.create({
  baseURL: domain, 
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "access-control-allow-origin": "*",
    "access-control-allow-methods": "GET,POST,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, Accept, Access-Control-Allow-Origin, access-control-allow-headers"
  },
  validateStatus: function() {return true}
  // transformResponse: [function (data) {
  //   return data;
  // }],
})
  