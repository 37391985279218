import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"


// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userDefaults = {
  uid           : 0,          // From Auth
  displayName   : "John Doe", // From Auth
  // about         : "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
  // photoURL      : require("@/assets/images/portrait/small/avatar-s-11.jpg"), // From Auth
  // status        : "online",
  userRole      : "public",
  institutionId : 1,   //Replaced for institution.id
  account       : null,
  profile       : null,
  institution   : null
}

const appDataDefaults = {
  profileTypes : [
    { profile_type : 1, profile_type_description : "Acudiente", role: "guardian" },
    { profile_type : 2, profile_type_description : "Estudiante", role: "student" },
    { profile_type : 3, profile_type_description : "Admin Cafetería", role: "owner" },
    { profile_type : 4, profile_type_description : "Gerente", role: "manager" },
    { profile_type : 5, profile_type_description : "Cajero", role: "seller" },
    { profile_type : 6, profile_type_description : "Admin Escuela", role: "school" },
    { profile_type : 7, profile_type_description : "Admin Tienda", role: "store" },
    { profile_type : 10, profile_type_description : "Admin País", role: "admin" },
    { profile_type : 11, profile_type_description : "Super Admin", role: "superadmin" },
  ],
  ownerPaymentStatus: [
    { status_type : 0, status_type_description : "Solicitado"},
    { status_type : 1, status_type_description : "Pagado"},
    { status_type : 2, status_type_description : "Pagado"},
  ],
  saPaymentStatus: [
    { status_type : -1, status_type_description : "Pendiente"},
    { status_type : 0, status_type_description : "Solicitado"},
    { status_type : 1, status_type_description : "Pagado"},
  ],
  bankAccountTypes: [
    {account_type: 0, account_type_description: "Caja de Ahorro"},
    {account_type: 1, account_type_description: "Cuenta Corriente"}
  ],
  accountStatus: [
    {id: 0, description: "Creado"},
    {id: 1, description: "Email verificado"},
    {id: 2, description: "Activo"},
    {id: 3, description: "Inactivo"},
    {id: 4, description: "No verificado"},
    {id: 5, description: "Activo"},
    {id: 6, description: "Eliminado"},
  ],
  cardTypes: [
    {card_type_id: 1, description: "Cédula"},
    {card_type_id: 2, description: "Pasaporte"},
  ],
  saleTypes: [
    {id: 1, description: "Ventas Tiptap", status: 1},
    {id: 2, description: "Ventas Tarjeta", status: 1},
    {id: 3, description: "Ventas Efectivo", status: 1},
    {id: 4, description: "Cortesia", status: 1},
    {id: 5, description: "Recarga en efectivo", status: 1},
  ],
  transactionTypes: [
    {id: 7, description: 'Efectivo', origin: 'Admin', type: 'Cash', status: 1},
    {id: 8, description: 'ACH', origin: 'Admin', type: 'ACH', status: 1},
    {id: 10, description: 'Yappy', origin: 'Admin', type: 'YAPPY', status: 1},
  ],
  externalUrls: [
    { key: 'school_dashboard', protocol: 'https', domain: 'school.paytiptap.com', subdomain: process.env.VUE_APP_DOMAIN.split('//')[1].split('-').length > 1 ? process.env.VUE_APP_DOMAIN.split('//')[1].split('-')[0] + '-' : ''}
  ]
}

const appDataStatusTypes = {
  statusTypes : [
    { status_type : 1, status_type_description : "Pendiente", role: "pending"},
    { status_type : 2, status_type_description : "Solicitado", role: "requested"},
    { status_type : 3, status_type_description : "Pagado", role: "paidout" },
  ],
}

const appDataStatusPending = {
  statusPending : [
    { status_type : 0, status_type_description : "Solicitado", role: "requested"},
    { status_type : 1, status_type_description : "Pagado", role: "paidout" },
  ],
}


// /////////////////////////////////////////////
// Atributes for locale -VeeValidate-
// /////////////////////////////////////////////

const esAttributes = {
  name : "nombre"
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser           : userDefaults,
    AppData                 : appDataDefaults,
    bodyOverlay             : false,
    isVerticalNavMenuActive : true,
    mainLayoutType          : themeConfig.mainLayoutType || "vertical",
    navbarSearchAndPinList  : navbarSearchAndPinList,
    reduceButton            : themeConfig.sidebarCollapsed,
    verticalNavMenuWidth    : "default",
    verticalNavMenuItemsMin : false,
    scrollY                 : 0,
    starredPages            : navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
    theme                   : themeConfig.theme || "light",
    themePrimaryColor       : colors.primary,
    typesStatus             : appDataStatusTypes,
    pendingStatus           : appDataStatusPending,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
    windowWidth: null,
    esMessageAttributes     : esAttributes,
    access_token            : (localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).auth.token : null),
}

export default state
